// @flow

import * as actions from 'client/constants/actions';
import * as api from 'api';

import { apiRequestAction } from './helpers/api-request';

export function getSlides(params: { collection: string, id: string }): any {
	return async (dispatch: Dispatch) => {
		const requestAction = apiRequestAction({
			action: actions.GET_SLIDES,
			request: api.getClientItems,
			params: { ...params },
		});

		return dispatch(requestAction);
	};
}
