import _ from 'lodash';

import * as actions from 'client/constants/actions';

export function setCurrentBrand(data = {}) {
	if (_.isEmpty(data)) {
		return;
	}

	return dispatch => dispatch({ type: actions.SET_CURRENT_BRAND, payload: { ...data } });
}
