// @flow

export const GET_SLIDES = {
	START: 'GET_SLIDES.START',
	SUCCESS: 'GET_SLIDES.SUCCESS',
	FAIL: 'GET_SLIDES.FAIL',
};

export const GET_BRANDS = {
	START: 'GET_BRANDS.START',
	SUCCESS: 'GET_BRANDS.SUCCESS',
	FAIL: 'GET_BRANDS.FAIL',
};

export const GET_GROUP = {
	START: 'GET_GROUP.START',
	SUCCESS: 'GET_GROUP.SUCCESS',
	FAIL: 'GET_GROUP.FAIL',
};

export const SET_POP_UP = 'SET_POP_UP';
export const SET_CURRENT_BRAND = 'SET_CURRENT_BRAND';
