// @flow

import React from 'react';
import _ from 'lodash';
import Icon from 'common/components/Icon';
import cn from 'classnames';
import { fileLink } from 'common/utils';
import { Responsive } from 'client/components/common/Responsive';
import { CAN_USE_DOM } from 'common/constants';
import { Footer } from 'client/components/common';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import css from './Slide.scss';
import Socials from '../Socialls';

type Props = {
	item: SlideType,
	slidesSize: number,
	brand: BrandType,
	nextSlide: () => any,
	previousSlide: () => any,
	isWelcome: boolean,
	slideIndex: number,
	goToHomepage: Function,
	likes: number,
	updateLike: Function,
};
const Slide = (props: Props) => {
	const {
		item,
		slidesSize,
		brand,
		nextSlide,
		previousSlide,
		isWelcome,
		slideIndex,
		goToHomepage,
		likes,
		updateLike,
	} = props;
	const brandId = _.get(brand, 'id');
	const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
	const isTablet = Responsive.isMatching(Responsive.TABLET);
	const title = _.get(item, 'title');
	// const likes = _.get(brand, 'likes');
	const brandName = _.get(brand, 'name');
	const brandTag = _.get(brand, 'tag');
	// const topMiniTitle = _.get(brand, 'title');
	const brandImageDesktop = _.get(item, 'imageDesktop', '');
	const brandImageMobile = _.get(item, 'imageMobile', '');
	const brandMainSlideColor = _.get(brand, 'mainSlideColor', '#5c5e65');
	const brandOtherSlidesColor = _.get(brand, 'otherSlidesColor', '#6482ff');
	const deskLink = fileLink(brandImageDesktop);
	const mobLink = fileLink(brandImageMobile);

	const getLocalItemIsLike = () => {
		if (CAN_USE_DOM) {
			return window.localStorage.getItem(brandId);
		}
	};

	const renderIsWelcomeButton = () => {
		return (
			<>
				{slidesSize === 1 ? (
					<div className={css.welcomeButtonWrapper}>{renderBackButton()}</div>
				) : (
					<div className={css.welcomeButtonWrapper}>
						<button className={css.welcomeButton} type="button" onClick={nextSlide}>
							<Icon type="arrow" className={css.arrowLeft} />
							<p>לקריאה</p>
						</button>
					</div>
				)}
			</>
		);
	};

	const renderBackButton = () => {
		return (
			<button type="button" className={css.backButton} onClick={() => goToHomepage()}>
				<span>חזרה לראשי</span>
				<Icon type="arrow" className={css.arrowLeft} />
			</button>
		);
	};

	const renderControlsArrows = () => {
		return (
			<div className={css.buttons}>
				<button type="button" onClick={previousSlide} className={css.arrowButton}>
					<Icon type="arrow" className={css.arrowRight} />
				</button>
				<>
					{slidesSize > slideIndex + 1 ? (
						<button type="button" onClick={nextSlide} className={css.arrowButton}>
							<Icon type="arrow" className={css.arrowLeft} />
						</button>
					) : (
						renderBackButton()
					)}
				</>
			</div>
		);
	};

	const linkDesktopImage = () => {
		if (deskLink) {
			return deskLink;
		}
		return mobLink;
	};

	const linkMobileImage = () => {
		if (mobLink) {
			return mobLink;
		}
		return deskLink;
	};

	return (
		<div
			className={css.innerPage}
			style={{ backgroundColor: isWelcome ? brandMainSlideColor : brandOtherSlidesColor }}
		>
			<div className={css.wrapper}>
				<section className={css.rightSection}>
					{/* {slideIndex !== 0 && <p className={css.topMiniTitle}>{topMiniTitle}</p>} */}
					<div className={cn(css.rightWrapper, isWelcome ? css.isRightWrapperWelcomeActive : '')}>
						<div className={css.rightInnerBlock}>
							<div className={css.textBlock}>
								<div className={slideIndex !== 0 ? css.innerTextBlock : ''}>
									<Fade duration={1000} distance={isDesktop || isTablet ? '50px' : '20px'} right>
										<h1 className={cn(slideIndex === 0 ? css.firstTitle : css.title)}>{title}</h1>
									</Fade>
									<Fade
										delay={200}
										duration={1000}
										distance={isDesktop || isTablet ? '50px' : '20px'}
										right
									>
										<h3>{item.subtitle}</h3>
									</Fade>
								</div>
							</div>
							<div className={cn(css.numberSection, !isWelcome && css.active)}>
								<Zoom duration={500}>{slideIndex === 0 ? 1 : `0${slideIndex}`}</Zoom>
							</div>
						</div>

						<div className={css.bottomSection}>
							{isWelcome ? renderIsWelcomeButton() : <>{isDesktop && renderControlsArrows()}</>}
							<div className={cn(css.bottomBlock, slideIndex !== 0 && css.marginForBottomBlock)}>
								<div className={css.likeBlock}>
									<button className={css.loveButton} type="button" onClick={updateLike}>
										<Icon
											type="love"
											className={cn(
												css.loveIcon,
												getLocalItemIsLike() ? css.loveIconDisable : ''
											)}
										/>
									</button>
									<div className={css.countLikes}>
										<Zoom duration={500}>
											<p>{likes}</p>
										</Zoom>
									</div>
								</div>
								<div className={css.bottomBlockRightPart}>
									<Socials isInHeader={false} />
									<p className={css.name}>בשיתוף {brandName}</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className={css.leftSection}>
					{!isDesktop && slideIndex !== 0 && renderControlsArrows()}
					<Fade duration={1000} distance="50px" left={!!isDesktop} right={!isDesktop}>
						<div>
							<img
								className={css.coverImage}
								src={isDesktop ? linkDesktopImage() : linkMobileImage()}
								alt=""
							/>
							{/* <p className={css.imgText}>קרדיט תמונה</p> */}
							<p className={css.imgText}>{brandTag}</p>
						</div>
					</Fade>
				</section>
			</div>
			<Footer />
		</div>
	);
};

export default Slide;
