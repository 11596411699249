// @flow
import produce from 'immer';

import * as actions from 'client/constants/actions';

const initialState: SlidesClient = {
	data: [],
	isBusy: false,
};

type Action =
	| { type: typeof actions.GET_SLIDES.START, payload: { collection: string } }
	| { type: typeof actions.GET_SLIDES.SUCCESS, payload: { items: Array<SlideType> } }
	| { type: typeof actions.GET_SLIDES.FAIL, payload: { collection: string } };

export default function slidesReducer(state: SlidesClient = initialState, action: Action) {
	return produce<any>(state, (draft: SlidesClient) => {
		switch (action.type) {
			case actions.GET_SLIDES.START:
				draft.isBusy = true;
				break;

			case actions.GET_SLIDES.SUCCESS:
				draft.data = action.payload.items;
				draft.isBusy = false;
				break;

			case actions.GET_SLIDES.FAIL:
				draft.isBusy = false;
				break;

			default:
				break;
		}
	});
}
