// @flow
import * as React from 'react';
import ynetLogo from 'assets/client/ynet-logo.svg';
import AccessibilityIcon from '../AccessibilityIcon';

import * as css from './SidePanel.scss';

type Props = {
	className?: string,
};

const SidePanel = ({ className }: Props) => {
	return (
		<div className={css.sidePanelWrapper}>
			<a href="https://www.ynet.co.il" target="_blank" className={css.logoWrapper} rel="noreferrer">
				<img src={ynetLogo} alt="logo" />
			</a>
			<AccessibilityIcon iconColor="#000" circleColor="#fff" className={css.acecessibility} />
		</div>
	);
};

SidePanel.defaultProps = {
	className: '',
};

export { SidePanel };
