/* eslint-disable max-len */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unescaped-entities */
// @flow
import * as React from 'react';
import _ from 'lodash';
import { fileLink } from 'common/utils';
import { CAN_USE_DOM } from 'common/constants';
// import { Responsive } from 'client/components/common/Responsive';
import { INNER_PAGE_ROUTE, GROUP_OF_BRANDS_INNER_PAGE_ROUTE } from '../../../constants/routes';
import * as css from './BrandPreview.scss';

type Props = {
	className?: string,
	item?: BrandType,
	history: RouterHistory,
	setCurrentBrand: Function,
	group?: GroupType | {},
};

const BrandPreview = ({ className, item, history, group, setCurrentBrand }: Props) => {
	const brandImageDesktop = _.get(item, 'imageDesktop', '');
	const brandImageMobile = _.get(item, 'imageMobile', '');
	const deskLink = fileLink(brandImageDesktop);
	const mobLink = fileLink(brandImageMobile);
	const tag = _.get(item, 'tag', '');
	const title = _.get(item, 'title', '');
	const name = _.get(item, 'name', '');
	// const id = _.get(item, 'id', '');
	const brandUrl = _.get(item, 'url', '');
	const groupUrl = _.get(group, 'url', '');

	const linkDesktopImage = () => {
		if (deskLink) {
			return deskLink;
		}
		return mobLink;
	};

	const linkMobileImage = () => {
		if (mobLink) {
			return mobLink;
		}
		return deskLink;
	};

	const clickBrandListener = (e: SyntheticEvent<HTMLDivElement>) => {
		const { url } = e.currentTarget.dataset;

		setCurrentBrand(item);

		const curUrl: string = CAN_USE_DOM ? window.location.href : '';
		const splitedUrl = curUrl.split('/');

		if (splitedUrl.length === 4) {
			history.push({ pathname: INNER_PAGE_ROUTE.replace(':brandId', url), search: `?s=0` });
		} else {
			history.push({
				pathname: GROUP_OF_BRANDS_INNER_PAGE_ROUTE.replace(':groupId', groupUrl).replace(':brandId', url),
				search: `?s=0`,
			});
		}
	};

	return (
		<div className={css.brandPreview} data-url={brandUrl} onClick={clickBrandListener}>
			<div className={css.brandImage}>
				<div
					className={css.brandImageDesktop}
					style={{ backgroundImage: `url("${linkDesktopImage()}")` }}
				></div>
				<div className={css.brandImageMobile} style={{ backgroundImage: `url("${linkMobileImage()}")` }}></div>
				<div className={css.brandTag}>{tag}</div>
			</div>
			<div className={css.texts}>
				<div className={css.title}>{title}</div>
				<div className={css.collaboration}>בשיתוף {name}</div>
			</div>
		</div>
	);
};

BrandPreview.defaultProps = {
	className: '',
	item: {},
	group: {},
};

export { BrandPreview };
