/* eslint-disable react/no-unescaped-entities */
// @flow
import * as React from 'react';
import classNames from 'classnames';
import yitLogo from 'assets/client/yit_logo.svg';
import * as css from './Footer.scss';

type Props = {
	className?: string,
};

const Footer = ({ className }: Props) => {
	const firstLink = 'https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html';
	const secondLink = 'https://z.ynet.co.il/short/content/2018/privacypolicy/policy.html';
	const thirdLink = 'https://yit.co.il/';

	return (
		<footer className={classNames(css.footer, className)}>
			<div className={css.footerWrapper}>
				<div className={css.linksWrapper}>
					<a href={firstLink} target="_blank" rel="noreferrer">
						תנאי שימוש
					</a>
					<a href={secondLink} target="_blank" rel="noreferrer">
						מדיניות פרטיות
					</a>
					<a href={thirdLink} target="_blank" rel="noreferrer">
						עיצוב, אפיון ופיתוח <img src={yitLogo} alt="yit-logo" />
					</a>
				</div>
			</div>
		</footer>
	);
};

Footer.defaultProps = {
	className: '',
};

export { Footer };
