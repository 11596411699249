// @flow
import produce from 'immer';

import * as actions from 'client/constants/actions';

const initialState: BrandType = {
	id: '',
	createdDate: 0,
	groupId: '',
	url: '',
	name: '',
	title: '',
	imageDesktop: {
		file: {
			name: '',
			url: '',
		},
		remoteURL: '',
		selected: 'file',
	},
	imageMobile: {
		file: {
			name: '',
			url: '',
		},
		remoteURL: '',
		selected: 'file',
	},
	tag: '',
	likes: 0,
	seoTitle: '',
	seoDescription: '',
	shareImage: {
		file: {
			name: '',
			url: '',
		},
		remoteURL: '',
		selected: 'file',
	},
	slides: [],
};

type Action = { type: typeof actions.SET_CURRENT_BRAND, payload: ?any };

export default function currentBrandReducer(state: BrandType = initialState, action: Action) {
	return produce<any>(state, (draft: BrandType) => {
		switch (action.type) {
			case actions.SET_CURRENT_BRAND:
				return action.payload;

			default:
				break;
		}
	});
}
