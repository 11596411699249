/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as css from './Icon.scss';

const Icon = ({ type, className, wrapInSquare }) => {
	let Content;

	switch (type) {
		case 'preloader':
			Content = (
				<svg viewBox="25 25 50 50">
					<circle cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
				</svg>
			);
			break;
		case 'love':
			Content = (
				<svg
					id="Capa_1"
					enableBackground="new 0 0 512 512"
					height="512"
					viewBox="0 0 512 512"
					width="512"
					xmlns="http://www.w3.org/2000/svg"
					className={className}
				>
					<g>
						<g>
							<path d="m246.122 477.289c-144.417-126.367-246.122-193.304-246.122-299.774 0-80.513 57.4-146.515 136-146.515 54.544 0 95.017 33.497 120 81.015 24.981-47.515 65.454-81.015 120-81.015 78.609 0 136 66.015 136 146.515 0 106.457-101.572 173.291-246.122 299.773-5.657 4.949-14.1 4.949-19.756.001z" />
						</g>
					</g>
				</svg>
			);
			break;
		case 'arrow':
			Content = (
				<svg
					id="Layer"
					enableBackground="new 0 0 64 64"
					height="512"
					viewBox="0 0 64 64"
					width="512"
					xmlns="http://www.w3.org/2000/svg"
					className={className}
				>
					<path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z" />
				</svg>
			);
			break;

		default:
			break;
	}

	return <div className={classNames(wrapInSquare && css.icon, className)}>{Content}</div>;
};

Icon.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string.isRequired,
	wrapInSquare: PropTypes.bool,
};

Icon.defaultProps = {
	className: '',
	wrapInSquare: false,
};

export default Icon;
