// @flow
import { connect } from 'react-redux';
import { getSlides } from 'client/actions';
import _ from 'lodash';
import BrandPage from './BrandPage';

const mapState = (state: ClientStore) => ({
	slides: state.slides.data,
	brands: state.brands.data,
	currentBrand: _.get(state, 'currentBrand', {}),
	groupUrl: _.get(state, 'group.data.url', ''),
});

const mapDispatch = { getSlides };

export default connect(mapState, mapDispatch)(BrandPage);
