// @flow
import Api from './ApiWorker';

export const createItem = <I: AbstractItem>(params: CreateItemParams<I>): Promise<BackEndResponse<I>> =>
	Api.send<I>({
		url: '/crud/create',
		method: 'POST',
		body: params,
		secure: true,
	});

export const getItem = <I>(params: GetItemParams): Promise<BackEndResponse<I>> =>
	Api.send<I>({
		url: '/crud/get',
		method: 'POST',
		body: params,
		secure: true,
	});

export const getItems = <I, F>(params: GetItemsParams<F>): Promise<BackEndResponse<GetItemsResult<I>>> =>
	Api.send<GetItemsResult<I>>({
		url: '/crud/get-items',
		method: 'POST',
		body: params,
		secure: true,
	});

export const updateItem = <I: AbstractItem>(params: UpdateItemParams<I>): Promise<BackEndResponse<I>> =>
	Api.send<I>({
		url: '/crud/update',
		method: 'POST',
		body: params,
		secure: true,
	});

export const deleteItem = (params: DeleteItemParams): Promise<BackEndResponse<DeleteItemParams>> =>
	Api.send<DeleteItemParams>({
		url: '/crud/delete',
		method: 'POST',
		body: params,
		secure: true,
	});

/* eslint-disable indent */
export const updateItemsOrder = <I: UpdateItemOrderParams>(
	params: UpdateItemsOrderParams<I>
): Promise<BackEndResponse<UpdateItemsOrderResult<I>>> =>
	Api.send<UpdateItemsOrderResult<I>>({
		url: '/crud/update-order',
		method: 'POST',
		body: params,
		secure: true,
	});

export const validateItemField = (params: ValidateItemFieldParams): Promise<BackEndResponse<boolean>> =>
	Api.send<boolean>({
		url: '/crud/validate-item-field',
		method: 'POST',
		body: params,
		secure: true,
	});

export const updateLikes = <I: AbstractItem>(params: UpdateItemParams<I>): Promise<BackEndResponse<I>> =>
	Api.send<I>({
		url: '/crud/update-likes',
		method: 'POST',
		body: params,
		secure: false,
	});
