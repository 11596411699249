import { CAN_USE_DOM } from '../constants';

export const dataLayerEvents = {
	pageView: (url, label) =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'virtualPageview',
			virtualPageURL: `${url}`,
			virtualPageTitle: `${label}`,
		}),
	clickOnShare: (network, position) =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'share_events',
			Category: 'Social',
			Action: `Share From ${position}`,
			Label: `${network}`,
		}),

	clickOnSlideButton: (direction, title) =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_Event',
			Category: 'Navigation',
			Action: `${direction}`,
			Label: `${title}`,
		}),

	clickOnLikeIcon: title =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_Event',
			Category: 'Navigation',
			Action: 'Like',
			Label: `${title}`,
		}),
};
