/* eslint-disable max-len */
// @flow
import * as React from 'react';
import classNames from 'classnames';

import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
import { Header, Footer, SidePanel, BrandPreview } from 'client/components/common';
// import { APP_CONFIG, IS_DEVEL } from 'common/constants';
import { Helmet } from 'react-helmet-async';
import faviconImg from 'assets/favicon.png';
import Masonry from 'react-masonry-css';
import * as css from './Intro.scss';

type Props = {|
	...EnhancedProps,
	in: boolean,
	// location: RouterLocation,
	history: RouterHistory,
	brands: BrandType[],
	setCurrentBrand: Function,
	group?: GroupType | {},
|};

type State = {};

const breakpointColumnsObj = {
	default: 4,
	'767': 1,
};
class Intro extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;

	static defaultProps = {
		group: {},
	};

	componentDidMount() {
		const { onTransitionComplete } = this.props;
		this.transitionCompleteTimeout = setTimeout(onTransitionComplete, parseInt(css.transitionIn, 10));
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	renderHelmet = () => {
		const title = 'סופרברנדס 2021 – ynet';

		const description = 'סופרברנדס 2021 – ynet';

		const image = '/assets/client/share.png?v=2';

		return (
			<Helmet
				title={title}
				link={[{ rel: 'icon', type: 'image/png', href: faviconImg }]}
				meta={[
					{
						name: 'description',
						content: description,
					},
					{ property: 'og:title', content: title },
					{ property: 'og:description', content: description },
					{ property: 'og:image', content: image },
				]}
			/>
		);
	};

	render() {
		const { transitionState, history, brands, setCurrentBrand, group } = this.props;

		return (
			<div className={classNames(css.intro, css[transitionState])}>
				{this.renderHelmet()}
				<Header isBlackSuperBrand />
				<SidePanel />
				<Masonry
					breakpointCols={breakpointColumnsObj}
					className={css.myMasonryGrid}
					columnClassName={css.myMasonryGridColumn}
				>
					{brands.map(item => {
						return (
							<BrandPreview
								item={item}
								key={item.id}
								history={history}
								setCurrentBrand={setCurrentBrand}
								group={group}
							/>
						);
					})}
				</Masonry>
				<Footer />
			</div>
		);
	}
}

export default withTransition(Intro);
