/* eslint-disable max-len */
// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { withRouter, Switch } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { detectIE } from 'common/utils/detect-ie';
import Intro from 'client/components/pages/Intro';
import faviconImg from 'assets/favicon.png';
// import { CAN_USE_DOM } from 'common/constants';
import * as css from './App.scss';
import {
	ROOT_ROUTE,
	GROUP_OF_BRANDS_PAGE_ROUTE,
	INNER_PAGE_ROUTE,
	GROUP_OF_BRANDS_INNER_PAGE_ROUTE,
} from '../constants/routes';
import BrandPage from './pages/BrandPage';

type Props = {|
	history: RouterHistory,
	location: RouterLocation,
	isBusy?: boolean,
|};

type ReactObjRef = { current: HTMLDivElement | null };

class App extends React.PureComponent<Props> {
	contentRef: ReactObjRef;
	static defaultProps = {
		isBusy: true,
	};

	constructor(props: Props) {
		super(props);

		this.contentRef = React.createRef();
	}

	async componentDidMount() {
		const isIE = detectIE();

		if (!this.props.isBusy) {
			this.removeRootPreloader();
		}

		if (document && document.body) {
			document.body.setAttribute('data-browser', isIE ? 'ie' : 'not-ie');
		}
	}

	async componentDidUpdate(prevProps: Props) {
		if (!this.props.isBusy && prevProps.isBusy) {
			this.removeRootPreloader();
		}
	}

	renderHelmet = () => {
		const title = 'ynet superbrands';

		const description = 'ynet superbrands';

		const image = '/assets/client/share.jpg';

		return (
			<Helmet
				title={title}
				link={[{ rel: 'icon', type: 'image/png', href: faviconImg }]}
				meta={[
					{
						name: 'description',
						content: description,
					},
					{ property: 'og:title', content: title },
					{ property: 'og:description', content: description },
					{ property: 'og:image', content: image },
				]}
			/>
		);
	};

	removeRootPreloader = () => {
		const preloadingScreen: ?HTMLElement = document.querySelector('.preloading-screen');

		if (preloadingScreen) {
			preloadingScreen.addEventListener('transitionend', (e: TransitionEvent) => {
				setTimeout(() => {
					if (document && document.body && e.target === preloadingScreen) {
						document.body.removeChild(preloadingScreen);
					}
				}, 1000);
			});
			preloadingScreen.style.opacity = '0';
			preloadingScreen.style.zIndex = '-1000';
		}
	};

	renderRoute = (Component: Function) => {
		return ({ match, ...props }) => {
			return <Component {...props} in={match !== null} />;
		};
	};

	scrollTopContent = () => {
		if (this.contentRef && this.contentRef.current) {
			this.contentRef.current.scrollTo(0, 0);
		}
	};

	render() {
		return (
			<div id="app" className={css.app}>
				{/* {this.renderHelmet()} */}
				<div className={css.content} ref={this.contentRef}>
					<Switch>
						<Route exact path={ROOT_ROUTE}>
							{({ match, ...props }) => (
								<Intro location={props.location} history={props.history} in={!!match} />
							)}
						</Route>
						<Route exact path={INNER_PAGE_ROUTE}>
							{({ match, ...props }) => (
								<BrandPage location={props.location} history={props.history} in={!!match} />
							)}
						</Route>
						<Route exact path={GROUP_OF_BRANDS_PAGE_ROUTE}>
							{({ match, ...props }) => (
								<Intro location={props.location} history={props.history} in={!!match} />
							)}
						</Route>
						<Route exact path={GROUP_OF_BRANDS_INNER_PAGE_ROUTE}>
							{({ match, ...props }) => (
								<BrandPage location={props.location} history={props.history} in={!!match} />
							)}
						</Route>
					</Switch>
				</div>
			</div>
		);
	}
}

const mapState = (state: ClientStore) => ({
	isBusy: state.brands.isBusy && state.slides.isBusy,
});

const mapDispatch = {};

export default withRouter(connect(mapState, mapDispatch)(App));
