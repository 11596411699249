// @flow

/* eslint-disable max-len,react/jsx-tag-spacing */
import * as React from 'react';
import classNames from 'classnames';
import * as css from './Icon.scss';

type Props = {|
	type: string,
	className?: string,
	width?: number,
	height?: number,
	color?: string,
	secondaryColor?: string,
	onClick?: Function,
|};

/**
 * @link - https://material.io/tools/icons/?style=baseline
 * @return {XML}
 */
const Icon = ({ type, className, width, height, color, secondaryColor, ...iconProps }: Props) => {
	let icon = null;
	const svgProps = {
		width,
		height,
		viewBox: `0 0 ${width || 34} ${height || 34}`,
		xmlns: 'http://www.w3.org/2000/svg',
	};

	switch (type) {
		case 'accessibility':
			icon = (
				<svg {...svgProps}>
					<g id="Group_338" transform="translate(-18 -24)">
						<rect
							id="Rectangle_299"
							width="34"
							height="34"
							rx="17"
							transform="translate(18 24)"
							fill={secondaryColor}
						/>
						<g id="XMLID_28_" transform="translate(-19.379 32)">
							<path
								id="XMLID_31_"
								d="M178.532 3.418a1.709 1.709 0 1 0-1.709-1.709 1.709 1.709 0 0 0 1.709 1.709z"
								fill={color}
								transform="translate(-124.881)"
							/>
							<path
								id="XMLID_30_"
								d="M56.292 204.681a3.777 3.777 0 1 1-5.149-4.5v-1.6a5.281 5.281 0 1 0 6.313 7.269l-.6-1.172z"
								fill={color}
								transform="translate(0 -191.581)"
							/>
							<path
								id="XMLID_29_"
								d="M191.737 128.291l-2.289-4.5a1 1 0 0 0-.894-.549h-2.931v-.668h2.523a.789.789 0 0 0 .753-.753.752.752 0 0 0-.753-.753h-2.523V118.8a1.5 1.5 0 1 0-3.01 0v4.912a1.535 1.535 0 0 0 1.535 1.535h3.791l2.01 3.952a1 1 0 1 0 1.788-.91z"
								fill={color}
								transform="translate(-130.467 -113.162)"
							/>
						</g>
					</g>
				</svg>
			);
			break;
		case 'facebook_icon':
			icon = (
				<svg data-name="Group 98" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
					<g data-name="Group 97">
						<path
							data-name="Path 1251"
							d="M26 13a13 13 0 1 0-15.031 12.842v-9.084h-3.3V13h3.3v-2.864c0-3.258 1.941-5.058 4.91-5.058a19.991 19.991 0 0 1 2.91.254v3.2H17.15a1.879 1.879 0 0 0-2.119 2.03V13h3.605l-.576 3.758h-3.029v9.084A13 13 0 0 0 26 13"
							fill={secondaryColor}
						/>
						<path
							data-name="Path 1252"
							d="m312.392 211.68.576-3.758h-3.605v-2.439a1.879 1.879 0 0 1 2.119-2.03h1.639v-3.2a19.992 19.992 0 0 0-2.91-.254c-2.969 0-4.91 1.8-4.91 5.058v2.864H302v3.758h3.3v9.084a13.135 13.135 0 0 0 4.063 0v-9.083z"
							transform="translate(-294.332 -194.922)"
							fill={color}
						/>
					</g>
				</svg>
			);
			break;
		case 'whatsapp_icon':
			icon = (
				<svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
					<g data-name="Group 102">
						<path
							data-name="WA Logo"
							d="m0 26.125 1.837-6.708A12.946 12.946 0 1 1 13.054 25.9h-.005a12.934 12.934 0 0 1-6.187-1.576z"
							fill={color}
						/>
						<path
							data-name="WA Logo"
							d="M1.837 19.417A12.946 12.946 0 1 1 13.054 25.9h-.005a12.933 12.933 0 0 1-6.186-1.576L0 26.125zm5.737 2.8a10.744 10.744 0 0 0 5.476 1.5 10.761 10.761 0 1 0-9.115-5.042l.256.407-1.083 3.967 4.072-1.068zm7.593-3.233a18.6 18.6 0 0 1-1.844-.681A14.421 14.421 0 0 1 7.8 13.416a6.292 6.292 0 0 1-1.324-3.346 3.628 3.628 0 0 1 1.132-2.7 1.189 1.189 0 0 1 .863-.4c.216 0 .431 0 .62.011h.073c.188 0 .423 0 .655.554.27.648.916 2.24 1 2.4a.6.6 0 0 1 .027.567 2.2 2.2 0 0 1-.323.54c-.162.189-.34.422-.485.567-.162.161-.331.336-.142.66a9.749 9.749 0 0 0 1.8 2.241 8.834 8.834 0 0 0 2.6 1.605c.323.162.512.135.7-.081s.809-.945 1.024-1.268.431-.27.728-.162 1.887.89 2.21 1.052.539.243.62.378a2.7 2.7 0 0 1-.189 1.538A3.332 3.332 0 0 1 17.2 19.11a5.3 5.3 0 0 1-.785.062 4.106 4.106 0 0 1-1.248-.191z"
							fill={secondaryColor}
						/>
					</g>
				</svg>
			);
			break;
		case 'share':
			icon = (
				<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
					<g transform="translate(-18 -24)">
						<g>
							<g>
								<g>
									<g>
										<path
											fill="#fff"
											d="M9.8 11.591a5.032 5.032 0 0 1 0 2.818l9.246 4.9a3.986 3.986 0 1 1-.937 1.767l-9.247-4.9a5 5 0 1 1 0-6.352l9.247-4.9a4 4 0 1 1 .936 1.776z"
											transform="translate(33 40.998) translate(-419 -227) translate(419 227)"
										/>
									</g>
								</g>
							</g>
						</g>
					</g>
				</svg>
			);
			break;

		case 'copy_icon':
			icon = (
				<svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
					<g data-name="Group 100" transform="translate(-1230 -730)">
						<circle data-name="Ellipse 11" cx="13" cy="13" r="13" transform="translate(1230 730)" />
						<path
							data-name="Icon metro-link"
							fill={color}
							d="M8.874 10.941a.719.719 0 0 1-.51-.211 3.388 3.388 0 0 1 0-4.786l2.663-2.663a3.384 3.384 0 1 1 4.786 4.786L14.6 9.284a.721.721 0 1 1-1.02-1.02l1.217-1.217a1.942 1.942 0 0 0-2.75-2.747L9.384 6.964a1.944 1.944 0 0 0 0 2.746.721.721 0 0 1-.51 1.231zm-2.556 5.218a3.384 3.384 0 0 1-2.393-5.777l1.218-1.217a.721.721 0 0 1 1.02 1.02L4.945 11.4a1.942 1.942 0 0 0 2.746 2.746l2.663-2.663a1.944 1.944 0 0 0 0-2.746.721.721 0 1 1 1.02-1.02 3.388 3.388 0 0 1 0 4.786l-2.663 2.665a3.362 3.362 0 0 1-2.393.991z"
							transform="translate(1233.131 733.777)"
						/>
					</g>
				</svg>
			);
			break;

		default:
			break;
	}

	return (
		<i className={classNames(css.icon, className)} {...iconProps}>
			{icon}
		</i>
	);
};

Icon.defaultProps = {
	className: '',
	width: 34,
	height: 34,
	color: '#111',
	secondaryColor: 'none',
	onClick: () => {},
};

export { Icon };
