// @flow
import { combineReducers } from 'redux';
import type { Reducer, Action } from 'redux';

import popUp from './pop-up';
import slides from './slides';
import brands from './brands';
import currentBrand from './current-brand';
import group from './group';

const reducer: Reducer<ClientStore, Action<{ type: string, payload: any }>> = combineReducers({
	slides,
	brands,
	popUp,
	currentBrand,
	group,
});

export default reducer;
