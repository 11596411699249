// @flow
import produce from 'immer';

import * as actions from 'client/constants/actions';

const initialState: GroupClient = {
	data: null,
	isBusy: false,
};

type Action =
	| { type: typeof actions.GET_GROUP.START, payload: { collection: string } }
	| { type: typeof actions.GET_GROUP.SUCCESS, payload: GroupType }
	| { type: typeof actions.GET_GROUP.FAIL, payload: { collection: string } };

export default function groupReducer(state: GroupClient = initialState, action: Action) {
	return produce<any>(state, (draft: GroupClient) => {
		switch (action.type) {
			case actions.GET_GROUP.START:
				draft.isBusy = true;
				break;

			case actions.GET_GROUP.SUCCESS:
				draft.data = action.payload;
				draft.isBusy = false;
				break;

			case actions.GET_GROUP.FAIL:
				draft.isBusy = false;
				break;

			default:
				break;
		}
	});
}
