// @flow

import * as React from 'react';
import classNames from 'classnames';
// import { Responsive } from 'client/components/common/Responsive';
import ynetLogo from 'assets/client/ynet-logo.svg';
import superBrandLogo from 'assets/client/super-brands-logo.png';
import superBrandLogoBlack from 'assets/client/super-brands-logo-black.png';
import secondLogoDesktop from 'assets/client/second-logo-desktop.png';
import secondLogoMobile from 'assets/client/second-logo-mobile.png';
import { Link } from 'react-router-dom';
import Socialls from '../Socialls';
// import { Icon } from '../Icon';
import AccessibilityIcon from '../AccessibilityIcon';
import * as css from './Header.scss';

type Props = {
	className?: string,
	isBlackSuperBrand?: boolean,
	isHideSocialls?: boolean,
	withoutShadow?: boolean,
};

const Header = ({ className, isBlackSuperBrand, isHideSocialls, withoutShadow }: Props) => {
	// const isMobile = Responsive.isMatching(Responsive.MOBILE);
	const ynetLink = 'https://www.ynet.co.il';
	const superBrandLogoLink = 'http://www.superbrands.co.il/';
	const secondLogoLink = '/';

	return (
		<header className={classNames(css.header, withoutShadow && css.withoutShadow, className)}>
			<div className={css.headerInner}>
				<div className={css.rightPart}>
					<a href={ynetLink} className={css.ynetLogo} target="_blank" rel="noreferrer">
						<img src={ynetLogo} alt="ynet-logo" />
					</a>
					<Link to={secondLogoLink} className={css.secondLogoDesktop}>
						<img src={secondLogoDesktop} alt="logo" />
					</Link>
					<Link to={secondLogoLink} className={css.secondLogoMobile}>
						<img src={secondLogoMobile} alt="logo" />
					</Link>
					{!isBlackSuperBrand && (
						<a href={superBrandLogoLink} className={css.superBrandLogo} target="_blank" rel="noreferrer">
							<img src={superBrandLogo} alt="logo" />
						</a>
					)}
					{isBlackSuperBrand && (
						<a
							href={superBrandLogoLink}
							className={css.superBrandLogoBlack}
							target="_blank"
							rel="noreferrer"
						>
							<img src={superBrandLogoBlack} alt="logo" />
						</a>
					)}
					<a href={superBrandLogoLink} className={css.superBrandLogoMobile} target="_blank" rel="noreferrer">
						<img src={superBrandLogo} alt="logo" />
					</a>
				</div>

				<div className={classNames(css.leftPart, isHideSocialls && css.isHideSocialls)}>
					<Socialls />
					<AccessibilityIcon iconColor="#000" circleColor="#fff" className={css.accessability} />
				</div>
			</div>
		</header>
	);
};

Header.defaultProps = {
	className: '',
	isBlackSuperBrand: false,
	isHideSocialls: false,
	withoutShadow: false,
};

export { Header };
