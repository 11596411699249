/* eslint-disable indent */
// @flow
import Api from './ApiWorker';

export const getClientItems = <I>(params: {
	collection: string,
	id: string,
}): Promise<BackEndResponse<GetItemsResult<I>>> =>
	Api.send<GetItemsResult<I>>({
		url: `/client/get-items?id=${params.id}&c=${params.collection}`,
		method: 'GET',
		secure: false,
	});
