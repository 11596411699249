/* eslint-disable no-useless-return */
// @flow

import * as React from 'react';
import classNames from 'classnames';

import { share } from 'client/utils/share';
import { IS_DEVEL, CAN_USE_DOM } from 'common/constants';
import { Responsive } from 'client/components/common/Responsive';
import { dataLayerEvents } from 'common/utils/gtag';
import { SHARE_CONSTANTS, SHARES_POSITION } from 'client/constants';
import { Icon } from '../Icon';

import * as css from './Socials.scss';

type Props = {
	className?: string,
	isInHeader?: boolean,
};

type State = {
	isOpen: boolean,
	isShowCopyText: boolean,
};

type ReactObjRef = { current: HTMLDivElement | null };
export default class Socials extends React.Component<Props, State> {
	sociallsRef: ReactObjRef;
	static defaultProps = {
		className: '',
		isInHeader: true,
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			isOpen: false,
			isShowCopyText: false,
		};
		this.sociallsRef = React.createRef();
	}

	componentDidMount() {
		document.addEventListener('click', this.documentClickListener);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.documentClickListener);
	}

	documentClickListener = (e: MouseEvent) => {
		if (this.sociallsRef.current) {
			if (this.sociallsRef.current.contains((e.target: any))) return false;
		}

		this.setState({
			isOpen: false,
		});
	};

	toggleOpenMenu = (e: SyntheticEvent<HTMLDivElement>) => {
		this.setState(prevState => ({
			isOpen: !prevState.isOpen,
		}));
	};

	onShare = (e: SyntheticEvent<HTMLButtonElement>) => {
		const { isInHeader } = this.props;
		const type = e.currentTarget.dataset.id;
		const domain = CAN_USE_DOM ? window.location.href : '';
		if (!IS_DEVEL) {
			if (isInHeader) {
				dataLayerEvents.clickOnShare(type, SHARES_POSITION.HEADER);
				share(type, domain);
			} else {
				dataLayerEvents.clickOnShare(type, SHARES_POSITION.CONTENT);
				share(type, domain);
			}
		}
	};

	onCopyClick = (e: SyntheticEvent<HTMLButtonElement>) => {
		const { isShowCopyText } = this.state;
		const { copy } = e.currentTarget.dataset;
		const type = e.currentTarget.dataset.id;
		const { isInHeader } = this.props;

		if (isShowCopyText) return;

		if (document && document.body) {
			const el = document.createElement('textarea');
			// eslint-disable-next-line max-len
			el.value = `${copy}`;
			// flow-disable-next-line
			document.body.appendChild((el: HTMLTextAreaElement));
			el.select();
			document.execCommand('copy');
			// flow-disable-next-line
			document.body.removeChild((el: HTMLTextAreaElement));
		}

		if (!IS_DEVEL) {
			dataLayerEvents.clickOnShare(type);
			if (isInHeader) {
				dataLayerEvents.clickOnShare(type, SHARES_POSITION.HEADER);
			} else {
				dataLayerEvents.clickOnShare(type, SHARES_POSITION.CONTENT);
			}
		}

		this.setState({
			isShowCopyText: true,
		});

		setTimeout(() => {
			this.setState({
				isShowCopyText: false,
			});
		}, 2000);
	};

	render() {
		const { className, isInHeader } = this.props;
		const { isOpen, isShowCopyText } = this.state;
		const copyUrl = CAN_USE_DOM && window.location.href;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);

		return (
			<div
				className={classNames(css.socials, isOpen && css.open, isInHeader && css.inHeader, className)}
				ref={this.sociallsRef}
			>
				<div className={css.overflowWrap}>
					<button
						type="button"
						className={classNames(css.button, css.socialButton)}
						data-id={SHARE_CONSTANTS.WHATSAPP}
						onClick={this.onShare}
					>
						<Icon
							type="whatsapp_icon"
							width={26}
							height={26}
							color={isMobile && isInHeader ? '#000' : '#fff'}
							secondaryColor={isMobile && isInHeader ? '#fff' : '#000'}
							className={css.iconWhatsapp}
						/>
					</button>
					<button
						className={classNames(css.button, css.socialButton)}
						type="button"
						data-id={SHARE_CONSTANTS.FACEBOOK}
						onClick={this.onShare}
					>
						<Icon
							type="facebook_icon"
							width={26}
							height={26}
							color={isMobile && isInHeader ? '#000' : '#fff'}
							secondaryColor={isMobile && isInHeader ? '#fff' : '#000'}
							className={css.iconFacebook}
						/>
					</button>
					<button
						type="button"
						className={classNames(css.button, css.copyButton)}
						onClick={this.onCopyClick}
						data-copy={copyUrl}
						data-id={SHARE_CONSTANTS.COPY}
					>
						<Icon type="copy_icon" width={26} height={26} color="#fff" className={css.iconCopy} />
						<p className={classNames(css.copyText, isShowCopyText && css.show)}>הקישור הועתק</p>
					</button>
				</div>
				<div className={css.toggleBtn} onClick={this.toggleOpenMenu}>
					<Icon type="share" width={60} height={60} color="#000" className={css.iconToggle} />
				</div>
			</div>
		);
	}
}
