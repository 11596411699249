// @flow
import { connect } from 'react-redux';
// import _ from 'lodash';
import { setCurrentBrand } from 'client/actions/current-brand';
import Intro from './Intro';

const mapState = (state: ClientStore) => ({
	brands: state.brands.data,
	group: state.group.data,
});

const mapDispatch = { setCurrentBrand };

export default connect(mapState, mapDispatch)(Intro);
