/* eslint-disable no-undef */
// @flow
// flow-disable-next-line
export const APP_CONFIG = APPLICATION_CONFIG;
export const ORIGINAL_IMAGE_IDENTIFIER = '_xoriginal';
export const APP_CONTAINER_CLASS = 'root';
export const APP_CONTAINER_SELECTOR = `.${APP_CONTAINER_CLASS}`;
export const CAN_USE_DOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
export const IS_DEVEL = process.env.NODE_ENV !== 'production';

export const SHARE_CONSTANTS = {
	WHATSAPP: 'WhatsApp',
	TWITTER: 'Twitter',
	FACEBOOK: 'Facebook',
};
