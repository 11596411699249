// @flow
import produce from 'immer';

import * as actions from 'client/constants/actions';

const initialState: BrandsClient = {
	data: [],
	isBusy: false,
};

type Action =
	| { type: typeof actions.GET_BRANDS.START, payload: { collection: string } }
	| { type: typeof actions.GET_BRANDS.SUCCESS, payload: { items: Array<BrandType> } }
	| { type: typeof actions.GET_BRANDS.FAIL, payload: { collection: string } };

export default function brandsReducer(state: BrandsClient = initialState, action: Action) {
	return produce<any>(state, (draft: BrandsClient) => {
		switch (action.type) {
			case actions.GET_BRANDS.START:
				draft.isBusy = true;
				break;

			case actions.GET_BRANDS.SUCCESS:
				draft.data = action.payload.items;
				draft.isBusy = false;
				break;

			case actions.GET_BRANDS.FAIL:
				draft.isBusy = false;
				break;

			default:
				break;
		}
	});
}
