/* eslint-disable max-len */
// @flow
export const IS_DEVEL = process.env.NODE_ENV !== 'production';

export const SHARE_CONSTANTS = {
	WHATSAPP: 'WhatsApp',
	FACEBOOK: 'Facebook',
	EMAIL: 'Email',
	COPY: 'Copy',
};

export const SHARES_POSITION = {
	HEADER: 'Site Head',
	CONTENT: 'Selected Content',
};

export const COLLECTIONS = {
	SLIDES: 'slides',
	BRANDS: 'brands',
};
